import React, { useState } from "react"
import { graphql } from "gatsby"
import { useFlexSearch } from "react-use-flexsearch"
import { Formik, Form, Field } from "formik"

import Layout from "../components/layout"
import ListingsList from "../components/ListingsList"

const fixThumbnailUrl = (listings) => listings.map(listing => ({
  thumbnail: {
    url: listing.thumbnailUrl,
  },
  ...listing,
}));

const SearchPage = ({ data }) => {
  const { index, store } = data.localSearchListings
  const [query, setQuery] = useState(null)
  const results = useFlexSearch(query, index, JSON.parse(store))

  return (
    <Layout>
      <Formik
        initialValues={{ query: "" }}
        onSubmit={(values, { setSubmitting }) => {
          setQuery(values.query)
          setSubmitting(false)
        }}
      >
        <Form>
          <label htmlFor="query">Nyckelord:</label>
          <Field name="query" placeholder="Balkong, kakelugn, etc." />
          <button type="submit">Sök</button>
        </Form>
      </Formik>
      {results && results.length > 0 && (
        <div>
          <ListingsList title={`Bostäder som matchar "${query}"`} listings={fixThumbnailUrl(results)} />
        </div>
      )}
    </Layout>
  )
}

export default SearchPage

export const query = graphql`
  query {
    localSearchListings {
      index
      store
    }
  }
`
